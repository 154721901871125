import { ref, onMounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { useRouter } from '@/@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

function useUserValidation() {
  const toast = useToast()
  const { router } = useRouter()
  const isLoading = ref(false)

  onMounted(() => {
    fetchUserField()
  })

  const toggleValue = (idx, value) => {
    validateData.value[idx].valid = !validateData.value[idx].valid
  }

  const validateData = ref([])
  const filePdf = ref({})

  const fields = ['module', 'value', 'valid']

  const getModules = [
    { name: 'name', module: 'user.info.name' },
    { name: 'dob', module: 'user.info.dob' },
    { name: 'gender', module: 'user.info.gender' },
    { name: 'religion', module: 'user.info.religion' },
    { name: 'marital_status', module: 'user.info.marital_status' },
    { name: 'education', module: 'user.info.education' },
    { name: 'profession', module: 'user.info.profession' },
  ]

  const fetchUserField = () => {
    store
      .dispatch('master-user/fetchUserField', {
        id: router.currentRoute.params.id,
      })
      .then(response => {
        if (response.data.code === 200) {
          const data = response.data.data

          filePdf.value = data.path

          const validData = data.user_id.valid_data

          const userData = {
            name: data.user_id.name ?? '-',
            dob: data.user_id.dob ?? '-',
            gender: data.user_id.gender ?? '-',
            religion: data.user_id.religion ?? '-',
            marital_status: data.user_id.marital_status ?? '-',
            education: data.user_id.education ?? '-',
            profession: data.user_id.profession ?? '-',
          }

          // const userData = (({
          //   name,
          //   dob,
          //   gender,
          //   religion,
          //   marital_status,
          //   education,
          //   profession,
          // }) => ({
          //   name,
          //   dob,
          //   gender,
          //   religion,
          //   marital_status,
          //   education,
          //   profession,
          // }))(data.user_id)

          // const getName = Object.keys(validData).map(item => ({
          //   name: item,
          // }))

          // const getValue = Object.values(userData).map(item => ({
          //   value: item,
          // }))
          // const getValid = Object.values(validData).map(item => ({
          //   valid: item ? item : false,
          // }))

          // const mergeObject = getName.map((obj, index) => ({
          //   ...obj,
          //   ...getModules[index],
          //   ...getValue[index],
          //   ...getValid[index],
          // }))

          const getUserEntries = Object.entries(userData).map(e => ({
            name: e[0],
            value: e[1],
          }))

          const getValidEntries = Object.entries(validData).map(e => ({
            name: e[0],
            valid: e[1],
          }))

          const mergeByName = (a1, a2) =>
            a1.map(itm => ({
              ...a2.find(item => item.name === itm.name && item),
              ...itm,
            }))

          const mergeUserAndValid = mergeByName(getUserEntries, getValidEntries)
          const mergeModuleAndUser = mergeByName(getModules, mergeUserAndValid)

          validateData.value = mergeModuleAndUser
        } else validateData.value = undefined
      })
      .catch(error => {
        if (error.response.status === 404) {
          validateData.value = undefined
        }
      })
  }

  const updateUserValidation = () => {
    const getNameValid = validateData.value.reduce(
      (obj, item) => ((obj[item.name] = item.valid), obj),
      {},
    )
    isLoading.value = true
    store
      .dispatch('master-user/updateUserValidation', {
        id: router.currentRoute.params.id,
        data: getNameValid,
      })
      .then(() => {
        store.commit('socket/DECREASE_VALIDATION_REQUEST')
        isLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success validating user',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        router.replace({ name: 'master-user-validation' })
      })
      .catch(() => {
        isLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error validating user',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    validateData,
    updateUserValidation,
    fields,
    filePdf,
    toggleValue,
    isLoading,
  }
}

export default useUserValidation
