<template>
  <section v-if="validateData" id="user-validation">
    <b-row>
      <b-col cols="12" md="6">
        <b-card no-body class="border">
          <div class="card-title pt-2 px-2">
            {{ $t('Form Validation') }}
          </div>
          <b-table
            striped
            responsive
            :items="validateData"
            :fields="fields"
            class="mb-0"
          >
            <template #cell(module)="data">
              {{ $t(data.value) }}
            </template>
            <template #cell(value)="data">
              {{ data.value }}
            </template>
            <template #cell(valid)="data">
              <b-form-checkbox
                :disabled="!$can('create', 'FaQ')"
                v-model="data.value"
                @change="toggleValue(data.index, data.value)"
              />
            </template>
          </b-table>
          <div class="d-flex flex-row justify-content-end p-2">
            <b-button
              v-if="$can('create', 'FaQ')"
              variant="primary"
              @click="updateUserValidation"
              :disabled="isLoading"
            >
              <div v-if="isLoading" class="d-flex align-center">
                <b-spinner small type="grow" class="mr-1" />
                Loading...
              </div>
              <span v-else>
                {{ $t('common.save') }}
              </span>
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card>
          <div class="card-title">{{ $t('Document') }}</div>
          <b-embed type="iframe" aspect="1by1" :src="filePdf" allowfullscreen />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormCheckbox,
  BButton,
  BEmbed,
  BSpinner,
} from 'bootstrap-vue'
import { formatDate, avatarText } from '@core/utils/filter'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import useUserValidation from './useUserValidation'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormCheckbox,
    BButton,
    BEmbed,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'master-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      }
    })

    const {
      validateData,
      updateUserValidation,
      fields,
      filePdf,
      toggleValue,
      isLoading,
    } = useUserValidation()

    return {
      validateData,
      updateUserValidation,
      fields,
      filePdf,
      toggleValue,
      formatDate,
      avatarText,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
